<template>
  <div class="center">
    <div class="profile">
      <div class="profile-icon">
        <img v-if="userInfo && userInfo.avatar_url" :src="userInfo.avatar_url" />
      </div>
      <div v-if="userInfo && userInfo.nickname" class="profile-name">{{ userInfo.nickname }}</div>
      <div class="profile-desc">邀请你成为密友</div>
      <div class="profile-copy">
        <div>我的邀请码: &nbsp;{{ clipboardText }}</div>
        <div class="copy-btn" id="copy-btn" :data-clipboard-text="`#${clipboardText}#`" @click="copy">复制</div>
      </div>
      <div class="profile-btn copy-btn2" :data-clipboard-text="`#${clipboardText}#`" @click="open">
        打开APP 接受邀请
      </div>
    </div>

    <div class="center-tips">
      <div class="dot"></div>
      <div class="tips">如何投送你的照片到朋友的桌面</div>
      <div class="dot"></div>
    </div>

    <div class="sitg">
      <img src="~@/assets/images/app/iuv-default.png" />
    </div>

    <div class="bottom">
      <div class="bottom-img">
        <div class="img"><img src="~@/assets/images/app/iuv-icon.png" /></div>
        <div class="name">IUV-Widgets桌面小组件</div>
      </div>
      <div class="bottom-btn copy-btn2" :data-clipboard-text="`#${clipboardText}#`" @click="open">去下载</div>
    </div>
  </div>
</template>

<script>
  import Clipboard from 'clipboard';
  import API from '@/api/modules/App/widgets';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        clipboardText: this.replaceInviteCode(this.$route.query.invite_code) || '',
      };
    },
    async asyncData({ store, $http, params }) {
      const { invite_code } = params;

      const data = await $http.widgetsPost(API.GETINFO_BY_INVITECODE, {
        invite_code: invite_code.replace(/#/g, ''),
      });

      store.commit('app/userInfo/SET_USER_INFO', data);
    },
    computed: {
      ...mapGetters('app/userInfo', ['userInfo']),
    },
    // mounted() {
    //   this.clipboardText = this.$route.query.invite_code;
    // },
    methods: {
      replaceInviteCode(code) {
        return code ? code.replace(/#/g, '') : '';
      },
      copy() {
        this.$track({ page_title: 'iuv小组件', element_name: '复制邀请链接' });

        const clipboard = new Clipboard('#copy-btn');
        clipboard.on('success', (e) => {
          e.clearSelection();
          this.$toast('复制成功');
        });
        clipboard.on('error', () => {
          this.$toast('复制失败');
        });
      },
      open() {
        this.$track({ page_title: 'iuv小组件', element_name: '打开APP' });
        const clipboard = new Clipboard('.copy-btn2');
        clipboard.on('success', (e) => {
          e.clearSelection();
          this.$toast('复制成功');
          window.location.href = 'https://apps.apple.com/cn/app/1565296362';
        });
        clipboard.on('error', () => {
          this.$toast('复制失败');
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .center {
    width: 7.5rem;
    background: url('~@/assets/images/app/iuv-bg.png') no-repeat center center;
    background-size: 100% 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 0.5rem;
  }

  .profile {
    width: 6.9rem;
    height: 6.76rem;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 0.48rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    .profile-icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-top: 0.28rem;
      border-radius: 50%;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
      }
    }
    .profile-name {
      height: 0.59rem;
      font-size: 0.42rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.59rem;
      margin-top: 0.14rem;
    }
    .profile-desc {
      height: 0.4rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #676767;
      line-height: 0.4rem;
      margin-top: 0.12rem;
    }
    .profile-copy {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 0.44rem;
      font-size: 0.32rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.44rem;
      margin-top: 0.48rem;
      .copy-btn {
        margin-left: 0.5rem;
        height: 0.45rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f76693;
        line-height: 0.45rem;
      }
    }
    .profile-btn {
      width: 5.9rem;
      height: 1.08rem;
      background: #f76693;
      border-radius: 0.55rem;

      font-size: 0.32rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 1.08rem;
      text-align: center;
      margin-top: 0.25rem;
    }
  }

  .center-tips {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 0.78rem;
    margin-bottom: 0.3rem;
    .dot {
      width: 0.1rem;
      height: 0.1rem;
      background: #f76693;
    }
    .tips {
      height: 0.4rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f76693;
      line-height: 0.4rem;
      margin: 0 0.14rem;
    }
  }

  .sitg {
    width: 6.9rem;
    height: 8.22rem;
    background: #ffffff;
    border-radius: 0.48rem 0.48rem 0rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 5.88rem;
      height: 5.88rem;
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.53rem;
    background: #ffffff;
    box-shadow: 0rem -0.04rem 0.23rem 0rem rgba(0, 0, 0, 0.17);
    border-radius: 0.2rem 0.2rem 0rem 0rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // & > div {
    // margin-top: 0.33rem;
    // }

    .bottom-img {
      margin-left: 0.42rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 0.86rem;
      .img {
        width: 0.86rem;
        height: 0.86rem;
        background: #ffd8d8;
        border-radius: 0.05rem;
        img {
          width: 0.86rem;
          height: 0.86rem;
          border-radius: 0.05rem;
        }
      }
      .name {
        height: 0.44rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 0.44rem;
        margin-left: 0.25rem;
      }
    }
    .bottom-btn {
      margin-right: 0.42rem;

      width: 1.68rem;
      height: 0.6rem;
      background: #f76693;
      border-radius: 0.3rem;

      font-size: 0.28rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.6em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
